import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Link as MuiLink,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core'
import {
  ManageBoardDeletionStatus,
  ManageInvitationStatus,
  useUpdatePersonTenantAssociationRoleMutation
} from '../../report-activations'
import { Spacer, useToasts } from '@vestaboard/installables'
import { useEditSubscriptionMuted, useMigrateBoard } from '../../../api'

import Alert from '@material-ui/lab/Alert'
import { ApplyActivationCode } from './ApplyActivationCode'
import { BoardInvite } from './BoardInvite'
import { BoardStyle } from './BoardStyle'
import { DigitalDevice } from './DigitalDevice'
import { FormattedDateTime } from '../../formatted-date-time'
import Helmet from 'react-helmet'
import { HubspotDealId } from './HubspotDealId'
import { InternalSwitch } from './InternalSwitch'
import { Link } from '../../link'
import { Messages } from './Messages'
import React from 'react'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import { TruncatedId } from '../../truncated-id'
import { useAddPlusSubscription } from '../hooks/useAddPlusSubscription'
import { useBoardDetailQuery } from '../hooks/useBoardDetailQuery'
import { useExpirePlusSubscription } from '../hooks/useExpirePlusSubscription'
import { useFlags } from '../../../providers/FlagsProvider'
import { useHistory } from 'react-router'
import { useSnackbar } from 'material-ui-snackbar-provider'

interface IBoardDetailProps {
  boardId: string
}

const useStyles = makeStyles({
  title: {
    paddingBottom: 14
  },
  devicesTitle: {
    paddingTop: 32,
    paddingBottom: 14
  }
})

type ChannelMutedToggleProps = IChannelMutedToggleProps

interface IChannelMutedToggleProps {
  id: string
  muted?: boolean | null
}

export const ChannelEnabledToggle: React.FC<ChannelMutedToggleProps> = props => {
  const [enabled, setEnabled] = React.useState(!props.muted ?? true)
  const [editSubscriptionMuted] = useEditSubscriptionMuted()
  const snackbar = useSnackbar()

  const set = (v: boolean) => {
    setEnabled(v)
    editSubscriptionMuted({ variables: { subscriptionId: props.id, muted: !v } })
    snackbar.showMessage(`Channel ${props.id} was ${v ? 'enabled' : 'muted'}`)
  }

  return (
    <>
      <Switch checked={enabled} onChange={e => set(e.target.checked)} />
    </>
  )
}

export const BoardDetail = (props: IBoardDetailProps) => {
  const classes = useStyles()
  const history = useHistory()

  const [confirmAddPlus, setConfirmAddPlus] = React.useState(false)
  const [mutate] = useUpdatePersonTenantAssociationRoleMutation()
  const [expirePlusSubscription] = useExpirePlusSubscription()
  const [addPlusSubscription] = useAddPlusSubscription()
  const [confirmExpire, setConfirmExpire] = React.useState(false)
  const [showMessages, setShowMessages] = React.useState(false)
  const flags = useFlags()
  const [expiring, setExpiring] = React.useState(false)
  const [roleChanges, setRoleChanges] = React.useState<{ [key: string]: string }>({})
  const [simulateSubscriptionConfig, setSimulateSubscriptionConfig] = React.useState('')
  const { data, loading, error, refetch } = useBoardDetailQuery(props.boardId)
  const [migrateBoard] = useMigrateBoard()
  const { addToast } = useToasts()

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <Alert severity='error'>Board not found.</Alert>
  }

  const personRows =
    data?.board.tenant.__typename === 'PersonTenant'
      ? data.board.tenant.members
          .filter(m => m.isCurrentMember)
          .map(member => (
            <TableRow key={member.id}>
              <TableCell>
                <Button variant='outlined' onClick={() => history.push(`/person/${member.person.id}`)}>
                  <TruncatedId value={member.person.id} />
                </Button>
              </TableCell>
              <TableCell>
                <Select
                  value={roleChanges[member.id] ?? member.role}
                  onChange={e => {
                    setRoleChanges({
                      ...roleChanges,
                      [member.id]: e.target.value as string
                    })
                    mutate({
                      variables: {
                        personTenantAssociationId: member.id,
                        newRole: e.target.value as string
                      }
                    })
                  }}>
                  <MenuItem value={'Owner'}>Owner</MenuItem>
                  <MenuItem value={'Admin'}>Admin</MenuItem>
                  <MenuItem value={'User'}>User</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <FormattedDateTime value={member.created} />
              </TableCell>
              <TableCell>
                {member.person.firstName} {member.person.lastName}
              </TableCell>
              <TableCell>{member.person.account.emailAddress}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))
      : null

  const revokedPersonRows =
    data?.board.tenant.__typename === 'PersonTenant'
      ? data.board.tenant.members
          .filter(m => !m.isCurrentMember)
          .map(member => (
            <TableRow style={{ background: 'gray' }} key={member.id}>
              <TableCell>
                <Button variant='outlined' onClick={() => history.push(`/person/${member.person.id}`)}>
                  <TruncatedId value={member.person.id} />
                </Button>
              </TableCell>
              <TableCell>{member.role}</TableCell>
              <TableCell>
                <FormattedDateTime value={member.created} />
              </TableCell>
              <TableCell>
                {member.person.firstName} {member.person.lastName}
              </TableCell>
              <TableCell>{member.person.account.emailAddress}</TableCell>
              <TableCell>
                <ManageInvitationStatus
                  boardTitle={data.board.title}
                  personName={`${member.person.firstName} ${member.person.lastName}`}
                  personEmailAddress={member.person.account.emailAddress}
                  personTenantAssociationId={member.id}
                  status={member.invitationStatus}
                  personId={member.person.id}
                  boardId={data.board.id}
                  onClose={() => refetch()}
                />
              </TableCell>
            </TableRow>
          ))
      : null

  const subscriptionRows = data?.board?.subscriptionSet?.subscriptions
    ?.filter(s => !!s.installation)
    ?.map(subscription => (
      <TableRow key={subscription.id}>
        <TableCell>
          <TruncatedId value={subscription.id} path={`/subscription/${subscription.id}`} />
        </TableCell>
        <TableCell>
          <FormattedDateTime value={parseInt(subscription.created)} />
        </TableCell>
        <TableCell>
          {subscription.installation?.id ? (
            <TruncatedId
              path={`/installation/${subscription.installation?.id}`}
              value={subscription.installation?.id}
            />
          ) : (
            '(none)'
          )}
        </TableCell>
        <TableCell>{subscription.title}</TableCell>
        <TableCell>
          <TruncatedId
            value={subscription.installation?.installable?.title || ''}
            path={`/installables/${subscription.installation?.installable?.id}`}
          />
        </TableCell>
        <TableCell>
          {subscription.messageSet?.id ? (
            <TruncatedId value={subscription.messageSet?.id} path={`/message-sets/${subscription.messageSet?.id}`} />
          ) : null}
        </TableCell>
        <TableCell>
          <TruncatedId
            value={subscription.marketplaceListing?.title || ''}
            path={`/marketplace-listings/${subscription.marketplaceListing?.id}`}
          />
        </TableCell>
        <TableCell>
          {subscription.configurationUrl ? (
            <div>
              <Button
                variant='outlined'
                onClick={() => setSimulateSubscriptionConfig(`${subscription.configurationUrl!}&platform=web`)}>
                Simulate
              </Button>
              <Spacer size='small' />
              <MuiLink
                rel='noopener noreferrer'
                target='_blank'
                href={`https://f31e349e.installables.vbrd.net/admin/errors/subscription/${subscription.id}`}>
                Server Logs
              </MuiLink>
            </div>
          ) : (
            <span />
          )}
        </TableCell>
        <TableCell>
          <ChannelEnabledToggle {...subscription} />
        </TableCell>
      </TableRow>
    ))

  const doExpire = async () => {
    setExpiring(true)
    await expirePlusSubscription({ variables: { boardId: data?.board.id ?? '' } })
    await refetch()
    setExpiring(false)
    setConfirmExpire(false)
  }

  const doAddPlus = async () => {
    setExpiring(true)
    await addPlusSubscription({ variables: { boardId: data?.board.id ?? '' } })
    await refetch()
    setExpiring(false)
    setConfirmAddPlus(false)
  }

  const plusProductApplication = data?.board.productApplications
    ?.filter(pa => pa.pricing?.product?.isPlus)
    ?.sort((a, b) => (a.ends > b.ends ? -1 : a.ends < b.ends ? 1 : 0))[0]

  return (
    <Box>
      {showMessages ? <Messages onClose={() => setShowMessages(false)} boardId={data?.board?.id || ''} /> : null}
      <Helmet>
        <meta charSet='utf-8' />
        <title>Board - {data?.board.title} - Detail Page - Vestaboard Superadmin</title>
      </Helmet>
      <Dialog
        maxWidth='lg'
        style={{ width: 1000, height: 800 }}
        open={!!simulateSubscriptionConfig}
        onClose={() => setSimulateSubscriptionConfig('')}>
        <iframe style={{ width: 1000, height: 800 }} src={simulateSubscriptionConfig} title='simulator config' />
      </Dialog>
      <Dialog maxWidth='lg' open={confirmExpire} onClose={() => setConfirmExpire(false)}>
        <DialogTitle>Remove Plus subscription from board</DialogTitle>
        <DialogContent dividers>
          Are you sure you would like to remove the Plus subscription for this board?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmAddPlus(false)}>{expiring ? <CircularProgress /> : 'No, Cancel'}</Button>
          <Button color='primary' variant='outlined' onClick={() => doExpire()}>
            {expiring ? <CircularProgress /> : 'Yes, Remove'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog maxWidth='lg' open={confirmAddPlus} onClose={() => setConfirmAddPlus(false)}>
        <DialogTitle>Add Plus subscription to board</DialogTitle>
        <DialogContent dividers>Are you sure you would like to add a Plus subscription to this board?</DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmExpire(false)}>{expiring ? <CircularProgress /> : 'No, Cancel'}</Button>
          <Button color='primary' variant='outlined' onClick={() => doAddPlus()}>
            {expiring ? <CircularProgress /> : 'Yes, Add'}
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant='button' display='block' gutterBottom>
        <Link to='/boards'>Board</Link>
      </Typography>
      <Typography variant='h4' className={classes.title}>
        {data?.board.title || props.boardId}
      </Typography>
      <Box>
        <Grid container>
          <Grid item md={6} sm={12}>
            <Card>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>Board Id</strong>
                    </TableCell>
                    <TableCell>{data?.board.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Board Friendly Identifier</strong>
                    </TableCell>
                    <TableCell>{data?.board.friendlyIdentifier}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Board Title</strong>
                    </TableCell>
                    <TableCell>{data?.board.title || 'Untitled'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Display Type</strong>
                    </TableCell>
                    <TableCell>
                      <BoardStyle
                        disabled={!!data?.board.devices.length}
                        id={data?.board.boardStyle as string}
                        boardId={data?.board.id as string}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Last Message</strong>
                    </TableCell>
                    <TableCell>
                      {data?.board.messageAppearances?.length ? (
                        <FormattedDateTime value={+data?.board.messageAppearances[0].appeared} />
                      ) : (
                        '(none)'
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Timezone</strong>
                    </TableCell>
                    <TableCell>
                      {data?.board.timezone?.timezoneCity || data?.board?.devices?.[0]?.timezone || '(none)'}
                    </TableCell>
                  </TableRow>
                  {data?.board.deleted ? (
                    <TableRow style={{ background: 'red' }}>
                      <TableCell>
                        <strong>Deleted</strong>
                      </TableCell>
                      <TableCell>
                        <FormattedDateTime value={data.board.deleted} />
                        <ManageBoardDeletionStatus boardId={data.board.id} onClose={() => refetch()} />
                      </TableCell>
                    </TableRow>
                  ) : null}
                  <TableRow>
                    <TableCell>
                      <strong>Internal Board</strong>
                    </TableCell>
                    <TableCell>
                      <InternalSwitch boardId={data?.board?.id || ''} internalBoard={!!data?.board?.internalBoard} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Hubspot Deal ID</strong>
                    </TableCell>
                    <TableCell>
                      <HubspotDealId boardId={data?.board?.id || ''} hubspotDealId={data?.board?.hubspotDealId || ''} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Is Plus</strong>
                    </TableCell>
                    <TableCell>
                      <Switch
                        onChange={e => {
                          if (e.target.checked === false) {
                            setConfirmExpire(true)
                          } else {
                            setConfirmAddPlus(true)
                          }
                        }}
                        checked={!!plusProductApplication ?? false}
                      />
                    </TableCell>
                  </TableRow>
                  {!!plusProductApplication ? (
                    <>
                      <TableRow>
                        <TableCell>
                          <strong>Plus Subscription ID</strong>
                        </TableCell>
                        <TableCell>
                          <TruncatedId value={plusProductApplication.id} path={`/plus/${plusProductApplication.id}`} />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Plus activation date</strong>
                        </TableCell>
                        <TableCell>
                          <FormattedDateTime value={plusProductApplication.started} hideTime />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <strong>Plus ending date</strong>
                        </TableCell>
                        <TableCell>
                          <FormattedDateTime value={plusProductApplication.ends} hideTime />
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <TableRow>
                      <TableCell>
                        <strong>Plus Redemption Code</strong>
                      </TableCell>
                      <TableCell>
                        <ApplyActivationCode boardId={data?.board?.id || ''} />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      <strong>Quiet hours</strong>
                    </TableCell>
                    <TableCell>
                      {data?.board.quietHoursBegin ?? 'None'} {data?.board.quietHoursEnd}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Pinned message until</strong>
                    </TableCell>
                    <TableCell>
                      {data?.board.pinnedMessage ? (
                        <Box>
                          <FormattedDateTime value={data.board.pinnedMessage.pinnedUntil}></FormattedDateTime>
                        </Box>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  {flags.messagesV2 ? null : (
                    <TableRow>
                      <TableCell>
                        <strong>Messages</strong>
                      </TableCell>
                      <TableCell>
                        <Button variant='outlined' onClick={() => setShowMessages(showMessages => !showMessages)}>
                          Show Messages
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      <strong>Web app link</strong>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant='outlined'
                        onClick={() => {
                          window.open(`https://web.vestaboard.com/board/${data?.board.id}/settings/`)
                        }}>
                        Use board in web app
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Live</strong>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant='outlined'
                        onClick={() => {
                          history.push(`/boards/${data?.board.id}/live`)
                        }}>
                        Live View
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={12}>
            <Typography variant='h5' className={classes.devicesTitle}>
              Users
            </Typography>
            <BoardInvite boardId={props.boardId} />
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Person ID</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Associated</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email Address</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {personRows}
                  {revokedPersonRows}
                </TableBody>
              </Table>
              {!data?.board?.devices?.length && (
                <Alert severity='info'>There are no devices associated with this board.</Alert>
              )}
            </Card>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <Typography variant='h5' className={classes.devicesTitle}>
              Channels (f.k.a. Subscriptions)
            </Typography>
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Channel ID (f.k.a. Subscription ID)</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Installation ID</TableCell>
                    <TableCell>User-Defined Title</TableCell>
                    <TableCell>Installable Title</TableCell>
                    <TableCell>Message Set ID</TableCell>
                    <TableCell>Marketplace Listing</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{subscriptionRows}</TableBody>
              </Table>
              {!data?.board?.subscriptionSet?.subscriptions?.length && (
                <Alert severity='info'>There are no channels (f.k.a. subscriptions) associated with this board.</Alert>
              )}
            </Card>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <Typography variant='h5' className={classes.devicesTitle}>
              Flagship Devices
            </Typography>
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Device ID</TableCell>
                    <TableCell>Device Type</TableCell>
                    <TableCell>Device Model</TableCell>
                    <TableCell>Network SSID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(data?.board?.devices || []).map(device => (
                    <TableRow key={device.id}>
                      <TableCell>
                        <Link to={`/devices/${device.id}`}>
                          <TruncatedId full value={device.id} />
                        </Link>
                      </TableCell>
                      <TableCell>{device.type}</TableCell>
                      <TableCell>{device?.model?.title || '(unknown)'}</TableCell>
                      <TableCell>{device?.configurationNetwork?.ssid || '(unknown)'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {!data?.board?.devices?.length && (
                <Alert severity='info'>There are no devices associated with this board.</Alert>
              )}
            </Card>
          </Grid>
        </Grid>
        <DigitalDevice id={data?.board.id} />
      </Box>
      <Box />
      <Spacer size='extraLarge' />
      <Button
        onClick={async () => {
          addToast('Migration task has been started', {
            appearance: 'success'
          })
          await migrateBoard({ variables: { boardId: props.boardId } })
        }}>
        <SyncAltIcon />
        <Typography>V2 Migrate</Typography>
      </Button>
    </Box>
  )
}
