import * as React from 'react'

import { Box, Button, Card, CircularProgress, ListItem, Select, TextField, Typography } from '@material-ui/core'
import { FlexHorizontal, Spacer } from '@vestaboard/installables'
import {
  UpdateMarketplaceConfigurationMutation,
  UpdateMarketplaceConfigurationMutationVariables
} from './__generated__/UpdateMarketplaceConfigurationMutation'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { Alert } from '@material-ui/lab'
import { MarketplaceConfigurationQuery } from './__generated__/MarketplaceConfigurationQuery'
import { MediaUploadButton } from '../../ui/MediaUploadButton'
import { gql } from 'apollo-boost'

type MarketplaceConfigurationProps = IMarketplaceConfigurationProps

interface IMarketplaceConfigurationProps {}

const MUTATION = gql`
  mutation UpdateMarketplaceConfigurationMutation(
    $featuredItems: [UpdateMarketplaceConfigurationFeaturedItemInput!]!
    $featuredChannel: UpdateMarketplaceConfigurationFeaturedItemInput
  ) {
    updateMarketplaceConfiguration(input: { featuredItems: $featuredItems, featuredChannel: $featuredChannel }) {
      success
    }
  }
`

const QUERY = gql`
  query MarketplaceConfigurationQuery {
    marketplaceListings {
      id
      title
    }
    featuredMarketplaceListings {
      image
      imageMedia {
        id
        cdnUrl
      }
      imageVariantMedia {
        id
        cdnUrl
      }
      description
      marketplaceListing {
        id
        title
      }
    }
  }
`

interface IFeaturedListing {
  id?: string | null
  image?: string | null
  imageMedia?: string | null
  variantImageMediaCdnUrl?: string | null
  variantImageMedia?: string | null
  description?: string | null
}

const emptyFeaturedListing = (): IFeaturedListing => ({ id: null, image: null })

export const MarketplaceConfiguration: React.FC<MarketplaceConfigurationProps> = props => {
  const { data, error, loading } = useQuery<MarketplaceConfigurationQuery>(QUERY, {
    fetchPolicy: 'no-cache'
  })
  const [mutation] = useMutation<
    UpdateMarketplaceConfigurationMutation,
    UpdateMarketplaceConfigurationMutationVariables
  >(MUTATION)
  const [featuredListings, setFeaturedListings] = React.useState<IFeaturedListing[]>([])
  const [saving, setSaving] = React.useState(false)

  React.useEffect(() => {
    setFeaturedListings(
      data?.featuredMarketplaceListings.map(featuredListing => ({
        id: featuredListing.marketplaceListing.id,
        image: featuredListing.imageMedia?.cdnUrl,
        imageMedia: featuredListing.imageMedia?.id,
        description: featuredListing.description
      })) ?? []
    )
  }, [data])

  const canSave = featuredListings.map(fl => fl.id && fl.image).filter(v => !!v).length === featuredListings.length

  const save = async () => {
    setSaving(true)
    await mutation({
      variables: {
        featuredItems: featuredListings.map(featuredListing => ({
          marketplaceListingId: featuredListing.id as string,
          image: featuredListing.imageMedia as string,
          description: featuredListing.description
        }))
      }
    })
    setSaving(false)
  }

  const options = (data?.marketplaceListings || []).map(ml => ({ label: ml.title, id: ml.id }))
  return (
    <Box>
      <FlexHorizontal spaceBetween>
        <Typography variant='h4'>Marketplace Features</Typography>
      </FlexHorizontal>
      <Spacer size='large' />
      {error ? (
        <Alert severity='error'>There was an error loading the marketplace features</Alert>
      ) : loading || !data ? (
        <CircularProgress />
      ) : (
        <>
          <Box display='flex' flexWrap='wrap'>
            {featuredListings.map((featuredListing, index) => (
              <FeaturedItem
                options={options}
                id={featuredListing.id ?? null}
                image={featuredListing.imageMedia ?? null}
                imagePreviewUrl={featuredListing?.image ?? null}
                description={featuredListing.description ?? null}
                aspectRatio={2 / 1}
                onChangeId={id =>
                  setFeaturedListings([
                    ...featuredListings.slice(0, index),
                    {
                      ...featuredListings[index],
                      id
                    },
                    ...featuredListings.slice(index + 1)
                  ])
                }
                onChangeImage={image => {
                  setFeaturedListings([
                    ...featuredListings.slice(0, index),
                    {
                      ...featuredListings[index],
                      imageMedia: image
                    },
                    ...featuredListings.slice(index + 1)
                  ])
                }}
                onChangeDescription={description =>
                  setFeaturedListings([
                    ...featuredListings.slice(0, index),
                    {
                      ...featuredListings[index],
                      description
                    },
                    ...featuredListings.slice(index + 1)
                  ])
                }
                onDelete={() =>
                  setFeaturedListings([...featuredListings.slice(0, index), ...featuredListings.slice(index + 1)])
                }
              />
            ))}
          </Box>
          <Spacer size='large' />
          <Button onClick={() => setFeaturedListings([...featuredListings, emptyFeaturedListing()])}>Add</Button>
          <Button onClick={() => save()} disabled={!canSave || saving} variant='contained' color='primary'>
            Save
          </Button>
          <Spacer size='large' />
        </>
      )}
    </Box>
  )
}

interface IFeaturedItemOption {
  id: string
  label: string
}

interface IFeaturedItemRow {
  id: string | null
  image: string | null
  imagePreviewUrl: string | null
  description: string | null
  options: IFeaturedItemOption[]
  onChangeId: (id: string) => any
  onChangeImage: (image: string) => any
  onChangeDescription: (description: string) => any
  onDelete?: () => any
  aspectRatio?: number
  onChangeVariantImage?: (image: string) => any
  variantImage?: string | null
  variantImagePreviewUrl?: string | null
}

type FeaturedItemRow = IFeaturedItemRow

const FeaturedItem: React.FC<FeaturedItemRow> = props => {
  const { onDelete } = props

  return (
    <Card style={{ marginBottom: 24, marginRight: 24, width: 448, padding: 24 }}>
      <Select
        variant='outlined'
        placeholder='Marketplace listing'
        value={props.id}
        onChange={e => props.onChangeId(e.target.value as string)}>
        {props.options
          .sort((d1, d2) => d1.label.localeCompare(d2.label))
          .map(option => (
            <ListItem value={option.id}>{option.label}</ListItem>
          ))}
      </Select>
      <Spacer size='large' />
      <Box>
        <MediaUploadButton
          mediaId={props.image}
          previewUrl={props.imagePreviewUrl}
          onUpload={id => props.onChangeImage(id)}
          aspectRatio={props.aspectRatio}
        />
      </Box>
      {props.onChangeVariantImage ? (
        <Box>
          <Spacer size='large' />
          <MediaUploadButton
            mediaId={props.variantImage}
            previewUrl={props.variantImagePreviewUrl}
            onUpload={id => props.onChangeVariantImage?.(id)}
            aspectRatio={16 / 9}
          />
        </Box>
      ) : null}
      <Spacer size='large' />
      <Box>
        <TextField
          multiline
          fullWidth
          variant='outlined'
          value={props.description}
          onChange={e => props.onChangeDescription(e.target.value)}
          placeholder='Description'
        />
      </Box>
      {onDelete && (
        <Box>
          <Spacer size='large' />
          <Button variant='outlined' onClick={onDelete}>
            Remove
          </Button>
        </Box>
      )}
    </Card>
  )
}
